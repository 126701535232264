export default function GetglobalError() {

    // window.onerror = function (message, source, lineno, colno, error) {
    //     console.error("Error caught: ", { message, source, lineno, colno, error });
    //     return true; 
    // };

    // window.onunhandledrejection = function (event) {
    //     console.error("Unhandled rejection: ", event.reason);
    // };
}