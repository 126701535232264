import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';
import {useRailwayTicketBookMutation} from '@services/AllApi';
import JdTikcetComp from '../JdTIcketPurchase/JdTikcetComp';
import {setMaxTabsId, setTabsId, setTrId} from '@Slice/JdSlice';
import PriceAndSeatsJdTicket from '../PriceAndSeatsJdTicket/PriceAndSeatsJdTicket';
import {formatCurrency} from '../../Functions/FormatCurrency';

const SvgViewer = ({svgContent}) => {
  return (
    <div className='w-full overflow-x-scroll lg:overflow-auto mt-4 vagonsvg'>
      <div className='w-[1000px] lg:w-full' dangerouslySetInnerHTML={{__html: svgContent}} />
    </div>
  );
};

function JdSubmit(props) {
  const [railwayTicketBook, {data: railwayTicketBookData, isSuccess: railwayTicketBookSuc, isLoading: railwayTicketBookLoading, error: railwayTicketBookErr}] = useRailwayTicketBookMutation();
  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const search_id = useSelector(state => state.JdSlice.search_id);
  const selectedWagon = useSelector(state => state.JdSlice.selectedWagon);
  const freePlaces = useSelector(state => state.JdSlice.freePlaces);
  const freePlacesBack = useSelector(state => state.JdSlice.freePlacesBack);
  const selectedSeats = useSelector(state => state.JdSlice.selectedSeats);
  const selectedSeatsBack = useSelector(state => state.JdSlice.selectedSeatsBack);
  const lang = useSelector(state => state.AllSlice.lang);

  const selectedJdSvg = useSelector(state => state.JdSlice.selectedJdSvg);
  const selectedJdSvgBack = useSelector(state => state.JdSlice.selectedJdSvgBack);
  const phoneNum = useSelector(state => state.JdSlice.phoneNum);
  const email = useSelector(state => state.JdSlice.email);
  const numberUpperSeats = useSelector(state => state.JdSlice.numberUpperSeats);
  const numberUpperSeatsBack = useSelector(state => state.JdSlice.numberUpperSeatsBack);
  const numberLowerSeats = useSelector(state => state.JdSlice.numberLowerSeats);
  const numberLowerSeatsBack = useSelector(state => state.JdSlice.numberLowerSeatsBack);
  const allPassengersInfo = useSelector(state => state.JdSlice.allPassengersInfo);
  const passengerType = useSelector(state => state.JdSlice.passengerType);
  const selectedJdItemBack = useSelector(state => state.JdSlice.selectedJdItemBack);
  const tabsId = useSelector(state => state.JdSlice.tabsId);
  const search_idBack = useSelector(state => state.JdSlice.search_idBack);
  const selectedWagonBack = useSelector(state => state.JdSlice.selectedWagonBack);
  const currency = useSelector(state => state.AllSlice.currency);
  const adults = useSelector(state => state.JdSlice.adults);
  const children = useSelector(state => state.JdSlice.children);
  const {jdBackDate} = props;

  const [loaderCircular, setLoaderCircular] = useState(false);
  const [pricesList, setPricesList] = useState([]);
  const [pricesListBack, setPricesListBack] = useState([]);

  const dispatch = useDispatch();

  const orderBuy = e => {
    e.preventDefault();
    setLoaderCircular(true);

    const passengers = selectedSeats.map((adtInfo, adtInx) => ({
      tariff: [
        {
          segment_type: 'RailwayThere',
          code: '1'
        },
        {
          segment_type: 'RailwayBack',
          code: '1'
        }
      ],
      document_type: allPassengersInfo[`Citizen${adtInx}`] === 'ru' ? 'P' : 'A',
      document_number: allPassengersInfo[`passportNum${adtInx}`].replace(' ', ''),
      last_name: allPassengersInfo[`lastName${adtInx}`]?.toUpperCase(),
      first_name: allPassengersInfo[`name${adtInx}`]?.toUpperCase(),
      middle_name: allPassengersInfo[`middleName${adtInx}`]?.toUpperCase(),
      birthday: allPassengersInfo[`birthdate${adtInx}`],
      citizenship: allPassengersInfo[`Citizen${adtInx}`],
      gender: allPassengersInfo[`gender${adtInx}`],
      type: '1',
      email: email,
      send_email: '1',
      phone: phoneNum
    }));

    const segments = [
      {
        search_id: search_id,
        segment_type: 'RailwayThere',
        train_number: selectedJdItem.train_number,
        departure_date_time: moment.parseZone(selectedJdItem.departure_date_time).format('YYYY-MM-DD HH:mm:ss'),
        car_number: selectedWagon.car_number,
        car_type: selectedWagon.car_type,
        service_class: selectedWagon.service_class,
        set_electronic_registration: '1',
        seats_from: String(Math.min(...selectedSeats.map(Number))),
        seats_to: String(Math.max(...selectedSeats.map(Number))),
        upper_seats: String(numberUpperSeats),
        lower_seats: String(numberLowerSeats),
        place_count: String(selectedSeats.length),
        one_section: '1',
        bedding: selectedWagon.bedding === true ? '1' : '0',
        comment: 'no comment',
        car_train_number: selectedWagon.car_train_number,
        is_bedding_selection_possible: selectedWagon.is_bedding_selection_possible === true ? '1' : '0',
        place_type: selectedWagon.place_reservation_type
      }
    ];

    if (jdBackDate && search_idBack) {
      segments.push({
        search_id: search_idBack,
        segment_type: 'RailwayBack',
        train_number: selectedJdItemBack.train_number,
        departure_date_time: moment.parseZone(selectedJdItemBack.departure_date_time).format('YYYY-MM-DD HH:mm:ss'),
        car_number: selectedWagonBack.car_number,
        car_type: selectedWagonBack.car_type,
        service_class: selectedWagonBack.service_class,
        set_electronic_registration: '1',
        seats_from: String(Math.min(...selectedSeatsBack.map(Number))),
        seats_to: String(Math.max(...selectedSeatsBack.map(Number))),
        upper_seats: String(numberUpperSeatsBack),
        lower_seats: String(numberLowerSeatsBack),
        place_count: String(selectedSeatsBack.length),
        one_section: '1',
        bedding: selectedWagonBack.bedding === true ? '1' : '0',
        comment: 'no comment',
        car_train_number: selectedWagonBack.car_train_number,
        is_bedding_selection_possible: selectedWagonBack.is_bedding_selection_possible === true ? '1' : '0',
        place_type: selectedWagonBack.place_reservation_type
      });
    }

    const data = {
      segments,
      passengers,
      lang
    };

    railwayTicketBook(data);
  };

  useEffect(() => {
    if (railwayTicketBookSuc && railwayTicketBookData) {
      setLoaderCircular(false);
      if (railwayTicketBookData?.data?.message) {
        toast.error(railwayTicketBookData.data.message);
      } else if (railwayTicketBookData?.tr_id) {
        dispatch(setTrId(railwayTicketBookData?.tr_id));
        toast.success('Ваш билет успешно забронирован');

        const start = window.scrollY;
        const startTime = performance.now();

        const scrollStep = currentTime => {
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / 300, 1);
          window.scrollTo(0, start * (1 - progress));
          if (progress < 1) {
            requestAnimationFrame(scrollStep);
          }
        };
        requestAnimationFrame(scrollStep);

        dispatch(setTabsId(tabsId + 1));
        dispatch(setMaxTabsId(tabsId + 1));
      }
    }
  }, [railwayTicketBookSuc]);

  useEffect(() => {
    if (railwayTicketBookErr) {
      if (railwayTicketBookErr?.data?.data.message) {
        setLoaderCircular(false);
        toast.error(railwayTicketBookErr?.data?.data?.message);
      }
    }
  }, [railwayTicketBookErr]);

  let svgContent = selectedJdSvg?.data[0];

  svgContent = svgContent?.replace(/�/g, '').replace(/`/g, "'");

  svgContent = svgContent?.replace(/id='Seat(\d+)'/g, (match, seatNumber) => {
    const isFree = freePlaces.includes(seatNumber);
    const isSelected = selectedSeats.includes(seatNumber);
    const fillColor = isSelected ? '#27AE60' : isFree ? '#A7CDEA' : '#CCCCCC';
    const pointerEvents = isFree ? 'auto' : 'none';

    return `
            id='Seat${seatNumber}' 
            onClick="window.handleSeatClick('${seatNumber}')" 
            style="fill: ${fillColor}; pointer-events: ${pointerEvents};"`;
  });

  let svgContentBack = jdBackDate && selectedJdSvgBack?.data[0] && selectedJdSvgBack?.data[0];
  svgContentBack = svgContentBack?.replace(/�/g, '').replace(/`/g, "'");

  svgContentBack = svgContentBack?.replace(/id='Seat(\d+)'/g, (match, seatNumber) => {
    const isFree = freePlacesBack.includes(seatNumber);
    const isSelected = selectedSeatsBack?.includes(seatNumber);
    const fillColor = isSelected ? '#27AE60' : isFree ? '#A7CDEA' : '#CCCCCC';
    const pointerEvents = isFree ? 'auto' : 'none';

    return `
                id='Seat${seatNumber}' 
                onClick="window.handleSeatClick('${seatNumber}')" 
                style="fill: ${fillColor}; pointer-events: ${pointerEvents};"`;
  });

  useEffect(() => {
    pricesList.length >= 1 && setPricesList([]);
    pricesListBack.length >= 1 && setPricesListBack([]);
    selectedSeats?.forEach(item => {
      selectedWagon?.places?.forEach(element => element.place_number == item && setPricesList(prevState => [...prevState, element.max_price[currency]]));
    });

    selectedSeatsBack?.forEach(item => {
      selectedWagonBack?.places?.forEach(element => element.place_number == item && setPricesListBack(prevState => [...prevState, element.max_price[currency]]));
    });
  }, [currency]);

  return (
    <form onSubmit={e => orderBuy(e)} className='grid grid-cols-12 gap-6'>
      <div className='col-span-12 lg:col-span-9 gap-6'>
        <div className=''>
          <div class='shadow-[0px_0px_15px_0px_#00000033] rounded-xl'>
            <div className='hidden lg:flex p-6'>
              <h2 className='text-2xl font-bold'>
                {selectedJdItem.passenger_departure_station_name} — {selectedJdItem.passenger_arrival_station_name}
              </h2>{' '}
              &nbsp; {jdBackDate && <h2 className='text-2xl'>туда и обратно, &nbsp;</h2>}
              {jdBackDate ? (
                <>
                  <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')} &nbsp;</h2>
                  <h2 className='text-2xl'>{moment(selectedJdItemBack?.departure_date_time).format('D-MMMM')}</h2>
                </>
              ) : (
                <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')}</h2>
              )}
            </div>

            <div className={`p-6 rounded-2xl`}>
              <JdTikcetComp selectedJdItem={selectedJdItem} />
            </div>
            {jdBackDate && (
              <div className='px-6'>
                <hr className='mt-2 h-[2px] bg-[#DCDDE0] border-none' />
              </div>
            )}
            {jdBackDate && selectedJdItemBack && (
              <div className={`${jdBackDate && tabsId === 3 && 'border-4 border-[#DC874D]'} p-6 rounded-2xl`}>
                <JdTikcetComp selectedJdItem={selectedJdItemBack} />
              </div>
            )}
          </div>
        </div>
        <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
          <h2 className='text-lg lg:text-2xl font-bold'>Вагона и места</h2>

          <div className='w-full'>
            {selectedJdSvg && (
              <>
                <h2 className='font-bold mb-2'>
                  Вагон {selectedWagon.car_type_name}-{selectedWagon.car_number}
                </h2>
                {selectedSeats.length >= 1 && selectedSeats.map(item => <h2 className='mb-2'>Место: {item}</h2>)}
                <SvgViewer svgContent={svgContent} />
              </>
            )}
          </div>

          {selectedJdItemBack && (
            <div className='w-full'>
              <>
                <h2 className='font-bold mb-2'>
                  Вагон {selectedWagonBack.car_type_name}-{selectedWagonBack.car_number}
                </h2>
                {selectedSeatsBack.length >= 1 && selectedSeatsBack.map(item => <h2 className='mb-2'>Место: {item}</h2>)}
                <SvgViewer svgContent={svgContentBack} />
              </>
            </div>
          )}
        </div>
        {adults + children > 0 &&
          [...Array(adults + children)]?.map((_, inx) => (
            <div className=' mt-6 shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl'>
              <h1 className='font-bold text-2xl'>Пассажиры</h1>
              <div className='h-[1px] bg-[#DCDDE0] my-5 w-full'></div>

              <div className='grid grid-cols-2 lg:grid-cols-4 gap-5'>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Имя</span>
                  {allPassengersInfo[`name${inx}`]}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Фамилия</span>
                  {allPassengersInfo[`lastName${inx}`]}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Отчество</span>
                  {allPassengersInfo[`middleName${inx}`]}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Гражданство</span>
                  {(allPassengersInfo[`Citizen${inx}`] == 'uz' && 'Узбекистан') || (allPassengersInfo[`Citizen${inx}`] == 'ru' && 'Россия') || (allPassengersInfo[`Citizen${inx}`] == 'kz' && 'Казахстан')}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Данные документа</span>
                  {allPassengersInfo[`passportNum${inx}`]}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Дата рождение</span>
                  {allPassengersInfo[`birthdate${inx}`]}
                </p>
                <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
                  <span className='text-sm font-medium text-[#8E8E92]'>Пол</span>
                  {(allPassengersInfo[`gender${inx}`] == 'M' && 'Мужской') || (allPassengersInfo[`gender${inx}`] == 'F' && 'Женский')}
                </p>
              </div>

              <div className='mt-5 text-sm  text-[#8E8E92] font-medium flex lg:items-center flex-col lg:flex-row gap-1'>
                <p>
                  {selectedJdItem.passenger_departure_station_name} - {selectedJdItem.passenger_arrival_station_name}
                </p>
                <div className='flex items-center'>
                  <p>{passengerType[inx]?.age == 'adt' ? 'Взрослый' : 'Ребенок'} -</p>
                  <p className='ml-1'>{selectedWagon && formatCurrency(pricesList[inx], currency)}</p>
                </div>
              </div>

              {selectedJdItemBack && (
                <div className='mt-3 text-sm  text-[#8E8E92] font-medium flex lg:items-center flex-col lg:flex-row gap-1'>
                  <p>
                    {selectedJdItemBack.passenger_departure_station_name} - {selectedJdItemBack.passenger_arrival_station_name}
                  </p>
                  <div className='flex items-center'>
                    <p>{passengerType[inx]?.age == 'adt' ? 'Взрослый' : 'Ребенок'} -</p>
                    <p className='ml-1'>{formatCurrency(pricesListBack[inx], currency)}</p>
                  </div>
                </div>
              )}
            </div>
          ))}

        <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
          <h1 className='text-2xl font-bold'>Контактная информация</h1>
          <p className='text-[#898989] my-2'>На почту мы отправим билет, вышлем уведомления об изменениях в рейсе или в случае других экстренных ситуаций</p>
          <div className='grid grid-cols-1 lg:grid-cols-2 mt-6 gap-8'>
            <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
              <span className='text-sm font-medium text-[#8E8E92]'>Электронная почта</span>
              {email}
            </p>

            <p className='flex flex-col text-base font-semibold text-[#1C1C1C]'>
              <span className='text-sm font-medium text-[#8E8E92]'>Телефон</span>
              {phoneNum}
            </p>
          </div>
        </div>
      </div>

      <PriceAndSeatsJdTicket loaderCircular={loaderCircular} />
    </form>
  );
}

export default JdSubmit;