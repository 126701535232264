import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import arrLeft from '@assets/icons/arrow-leftBalck.svg';
import {ReactComponent as ArrowRightBlack} from '@assets/icons/arrow-rightBlack.svg';
import {ReactComponent as ArrowRightGray} from '@assets/icons/arrow-rightGray.svg';
import menu from '@assets/icons/menuBlack.svg';
import arrowLeftWhite from '@assets/icons/arrow-leftWhite.svg';
import setting from '@assets/icons/setting-4.svg';
import TiketsJd from './TiketsJd';
import JdTicketPurchase from '@components/JdTIcketPurchase';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import JdPassengerInfo from '@components/JdPassengerInfo';
import JdPayment from '../../components/JdPayment/JdPayment';
import {setChosenPriceFilter, setChosenDateFilter, setTabsId} from '@Slice/JdSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import JdSubmit from '../../components/JdSubmit';
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function CustomTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TicketJd(props) {
  const {t} = useTranslation();
  const {date, fromRailwaysName, toRailwaysName, fromRailways, toRailways} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const maxTabsId = useSelector(state => state.JdSlice.maxTabsId);
  const value = useSelector(state => state.JdSlice.tabsId);
  const chosenPriceFilter = useSelector(state => state.JdSlice.chosenPriceFilter);
  const chosenDateFilter = useSelector(state => state.JdSlice.chosenDateFilter);

  const [filterPriceDropdown, setFilterPriceDropdown] = useState(null)
  const [filterDateDropdown, setFilterDateDropdown] = useState(null)

  const handleChange = (event, newValue) => {
    newValue <= maxTabsId && dispatch(setTabsId(newValue));
  };

  const handlePriceChange = (e) => {
    dispatch(setChosenPriceFilter(e.target.value))
  }

  const handleDateChange = (e) => {
    dispatch(setChosenDateFilter(e.target.value))
  }

  const clearFilter = () => {
    dispatch(setChosenPriceFilter('cheepFirst'))
    dispatch(setChosenDateFilter('clear'))
  }

  const parts = params.id?.split('&') || '';
  const jdBackDate = parts[6] && parts[6];

  return (
    <div className=' '>
      <div className='bg-[#0057BE] py-2 px-2 md:px-4 gap-1 lg:hidden flex items-center justify-between'>
        <img onClick={() => navigate('/')} className='w-8 h-8' src={arrowLeftWhite} alt='' />
        {fromRailwaysName && toRailwaysName && date && (
          <div className='text-[#fff] text-sm'>
            <p>
              {fromRailwaysName} - {toRailwaysName} {moment(date, 'YYYY-MM-DD').format('D-MMMM')}
            </p>
          </div>
        )}
        <img className='w-8 h-8' src={setting} alt='' />
      </div>
      <div className='container mx-auto'>
        <div className='flex mt-5 items-center ml-3 lg:ml-0  gap-2 overflow-x-scroll lg:overflow-auto'>
          <div className='py-2 px-3 flex items-center justify-center gap-3  cursor-pointer rounded-lg bg-[#F7F7F7]' onClick={(e) => setFilterPriceDropdown(e.currentTarget)}>
            <p className='whitespace-nowrap'>Цены</p>
            <img loading='lazy' className='rotate-[-90deg]' src={arrLeft} alt='' />
          </div>
          <Menu
            anchorEl={filterPriceDropdown}
            id='basic-menu'
            open={Boolean(filterPriceDropdown)}
            onClose={() => setFilterPriceDropdown(null)}
            disableScrollLock={true}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                padding: '20px',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                ml: '100px',
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 12,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          >
            <FormControl className=''>
              <FormLabel id='demo-radio-buttons-group-label'>{t('Filter')}</FormLabel>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' value={chosenPriceFilter} name='radio-buttons-group' onChange={e => handlePriceChange(e)}>
                <FormControlLabel value='cheepFirst' control={<Radio />} label={t('CheepFirst')} />
                <FormControlLabel value='cheepLast' control={<Radio />} label={'Сначала дорогие'} />
              </RadioGroup>
            </FormControl>
          </Menu>
          <div className='py-2 px-3 flex items-center justify-center gap-3 cursor-pointer rounded-lg bg-[#F7F7F7]' onClick={(e) => setFilterDateDropdown(e.currentTarget)}>
            <p className='whitespace-nowrap'>Время отправления / прибытия</p>
            <img loading='lazy' className='rotate-[-90deg]' src={arrLeft} alt='' />
          </div>
          <Menu
            anchorEl={filterDateDropdown}
            id='basic-menu'
            open={Boolean(filterDateDropdown)}
            onClose={() => setFilterDateDropdown(null)}
            disableScrollLock={true}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                padding: '20px',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                ml: '100px',
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 12,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          >
            <FormControl className=''>
              <FormLabel id='demo-radio-buttons-group-label'>{t('Filter')}</FormLabel>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' value={chosenDateFilter} name='radio-buttons-group' onChange={e => handleDateChange(e)}>
                <FormControlLabel value='depFirstTime' control={<Radio />} label={'birinchi ketishi'} />
                <FormControlLabel value='depLastTime' control={<Radio />} label={'ohiri ketish '} />
                <FormControlLabel value='arrFirstTime' control={<Radio />} label={'birinchi yetib borishi'} />
                <FormControlLabel value='arrLastTime' control={<Radio />} label={'ohiri yetib borish'} />
              </RadioGroup>
            </FormControl>
          </Menu>
          <div className='py-2 px-3 flex items-center justify-center gap-3 cursor-pointer rounded-lg bg-[#FDD9D7] text-[#F34235]'>
            <p className='whitespace-nowrap' onClick={clearFilter}>Сбросить</p>
          </div>
        </div>

        <Box sx={{borderBottom: 1, borderColor: '#A7A7AA'}}>
          <Tabs
            variant='scrollable'
            sx={{
              '& .MuiTabs-scroller': {overflowX: 'auto'} // Ensure scroller allows horizontal scroll
            }}
            scrollButtons='auto'
            value={value}
            className=''
            onChange={handleChange}
            TabIndicatorProps={{style: {backgroundColor: '#FBCF4C', height: '4px', overflowY: 'scroll'}}}
            aria-label='basic tabs example'
          >
            <Tab
              iconPosition='end'
              label='Выбор поезд туда'
              icon={value === 0 ? <ArrowRightBlack /> : <ArrowRightGray />}
              {...a11yProps(0)}
              sx={{
                color: value === 0 ? '#A7A7AA' : '#A7A7AA',
                '&:hover': {color: '#A7A7AA'},
                '&.Mui-selected': {color: '#1C1C1C'}
              }}
            />
            {jdBackDate && (
              <Tab
                iconPosition='end'
                label='Выбор поезд обратно'
                icon={value === 1 ? <ArrowRightBlack /> : <ArrowRightGray />}
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? '#A7A7AA' : '#A7A7AA',
                  '&:hover': {color: '#A7A7AA'},
                  '&.Mui-selected': {color: '#1C1C1C'}
                }}
              />
            )}
            <Tab
              iconPosition='end'
              label='Выбор мест туда'
              icon={jdBackDate ? value === 2 ? <ArrowRightBlack /> : <ArrowRightGray /> : value === 1 ? <ArrowRightBlack /> : <ArrowRightGray />}
              {...a11yProps(jdBackDate ? 2 : 1)}
              sx={{
                color: jdBackDate ? (value === 2 ? '#A7A7AA' : '#A7A7AA') : value === 1 ? '#A7A7AA' : '#A7A7AA',
                '&:hover': {color: '#A7A7AA'},
                '&.Mui-selected': {color: '#1C1C1C'}
              }}
            />
            {jdBackDate && (
              <Tab
                iconPosition='end'
                label='Выбор мест обратно'
                icon={value === 3 ? <ArrowRightBlack /> : <ArrowRightGray />}
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? '#A7A7AA' : '#A7A7AA',
                  '&:hover': {color: '#A7A7AA'},
                  '&.Mui-selected': {color: '#1C1C1C'}
                }}
              />
            )}

            <Tab
              iconPosition='end'
              label='Данные пассажиров'
              icon={jdBackDate ? value === 4 ? <ArrowRightBlack /> : <ArrowRightGray /> : value === 2 ? <ArrowRightBlack /> : <ArrowRightGray />}
              {...a11yProps(jdBackDate ? 4 : 2)}
              sx={{
                color: value === 2 ? '#A7A7AA' : '#A7A7AA',
                '&:hover': {color: '#A7A7AA'},
                '&.Mui-selected': {color: '#1C1C1C'}
              }}
            />
            <Tab
              iconPosition='end'
              label='Подтверждение'
              icon={jdBackDate ? value === 5 ? <ArrowRightBlack /> : <ArrowRightGray /> : value === 3 ? <ArrowRightBlack /> : <ArrowRightGray />}
              {...a11yProps(jdBackDate ? 5 : 3)}
              sx={{
                color: jdBackDate ? (value === 5 ? '#A7A7AA' : '#A7A7AA') : value === 3 ? '#A7A7AA' : '#A7A7AA',
                '&:hover': {color: '#A7A7AA'},
                '&.Mui-selected': {color: '#1C1C1C'}
              }}
            />
            <Tab
              label='Оплата'
              {...a11yProps(jdBackDate ? 6 : 4)}
              sx={{
                color: jdBackDate ? (value === 6 ? '#A7A7AA' : '#A7A7AA') : value === 4 ? '#A7A7AA' : '#A7A7AA',
                '&:hover': {color: '#A7A7AA'},
                '&.Mui-selected': {color: '#1C1C1C'}
              }}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <TiketsJd fromRailwaysName={fromRailwaysName} toRailwaysName={toRailwaysName} date={date} />
        </CustomTabPanel>
        {jdBackDate && (
          <CustomTabPanel value={value} index={1}>
            <TiketsJd fromRailwaysName={fromRailwaysName} fromRailways={fromRailways} toRailways={toRailways} toRailwaysName={toRailwaysName} date={date} jdBackDate={jdBackDate} />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={jdBackDate ? 2 : 1}>
          <JdTicketPurchase jdBackDate={jdBackDate} />
        </CustomTabPanel>
        {jdBackDate && (
          <CustomTabPanel value={value} index={3}>
            <JdTicketPurchase jdBackDate={jdBackDate} />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={jdBackDate ? 4 : 2}>
          <JdPassengerInfo jdBackDate={jdBackDate} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={jdBackDate ? 5 : 3}>
          <JdSubmit jdBackDate={jdBackDate} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={jdBackDate ? 6 : 4}>
          <JdPayment jdBackDate={jdBackDate} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

export default TicketJd;
