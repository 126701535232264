import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const getMyCityApi = createApi({
  reducerPath: "getMyCityPath",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.mysafar.ru",
  }),

  endpoints: (builder) => ({
    getMyCity: builder.mutation({
      query: (body) => ({
        url: "/main/geo",
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useGetMyCityMutation } = getMyCityApi;
