import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    jdticketData: undefined,
    jdgetRecommendationSuc: undefined,
    jdloadingGetRecommendation: undefined,
    jdgetRecommendationData: undefined,
    jdgetRecommendationErr: undefined,
    search_id: undefined,
    search_idBack: undefined,
    adults: 1,
    children: 0,
    train_number: undefined,
    train_numberBack: undefined,
    departure_date_time: undefined,
    departure_date_timeBack: undefined,
    jdticketInfoData: undefined,
    selectedJdItem: undefined,
    selectedJdItemBack: undefined,
    wagonInfo: [],
    wagonInfoBack: [],
    selectedWagon: undefined,
    selectedWagonBack: undefined,
    selectedSeats: [],
    selectedSeatsBack: [],
    trId: undefined,
    maxTabsId: 0,
    tabsId: 0,
    birthDate: null,
    name: null,
    lastName: null,
    middleName: null,
    citizenship: "ru",
    docNum: null,
    docType: "A",
    gender: "M",
    phoneNum: null,
    email: null,
    numberUpperSeats: 0,
    numberUpperSeatsBack: 0,
    numberLowerSeats: 0,
    numberLowerSeatsBack: 0,
    selectedJdSvg: "",
    selectedJdSvgBack: "",
    freePlaces: [],
    freePlacesBack: [],
    trId: undefined,
    allPassengersInfo: {},
    passengerType: [],
    jdTicketBackData: [],
    chosenPriceFilter: 'cheepFirst',
    chosenDateFilter: ''
}

export const slice = createSlice({
    name: 'JdSlice',
    initialState,
    reducers: {
        setJdticketData: (state, action) => {
            state.jdticketData = action.payload
        },
        setJdgetRecommendationSuc: (state, action) => {
            state.jdgetRecommendationSuc = action.payload
        },
        setJdloadingGetRecommendation: (state, action) => {
            state.jdloadingGetRecommendation = action.payload
        },
        setJdgetRecommendationData: (state, action) => {
            state.jdgetRecommendationData = action.payload
        },
        setJdgetRecommendationErr: (state, action) => {
            state.jdgetRecommendationErr = action.payload
        },
        setTrain_number: (state, action) => {
            state.train_number = action.payload
        },
        setTrain_numberBack: (state, action) => {
            state.train_numberBack = action.payload
        },
        setDeparture_date_time: (state, action) => {
            state.departure_date_time = action.payload
        },
        setDeparture_date_timeBack: (state, action) => {
            state.departure_date_timeBack = action.payload
        },
        setSearch_id: (state, action) => {
            state.search_id = action.payload
        },
        setSearch_idBack: (state, action) => {
            state.search_idBack = action.payload
        },
        addAdults: (state, action) => {
            state.adults = state.adults + 1
        },
        subtractAdults: (state, action) => {
            state.adults = state.adults - 1
        },
        addChildren: (state, action) => {
            state.children = state.children + 1
        },
        subtractChildren: (state, action) => {
            state.children = state.children - 1
        },
        setJdticketInfoData: (state, action) => {
            state.jdticketInfoData = action.payload
        },
        setSelectedJdItem: (state, action) => {
            state.selectedJdItem = action.payload
        },
        setSelectedJdItemBack: (state, action) => {
            state.selectedJdItemBack = action.payload
        },
        setWagonInfo: (state, action) => {
            state.wagonInfo = action.payload
        },
        setWagonInfoBack: (state, action) => {
            state.wagonInfoBack = action.payload
        },
        setSelectedWagon: (state, action) => {
            state.selectedWagon = action.payload
        },
        setSelectedWagonBack: (state, action) => {
            state.selectedWagonBack = action.payload
        },
        setSelectedSeatsSlice: (state, action) => {
            state.selectedSeats = action.payload
        },
        setSelectedSeatsSliceBack: (state, action) => {
            state.selectedSeatsBack = action.payload
        },
        setMaxTabsId: (state, action) => {
            state.maxTabsId = action.payload
        },
        setTabsId: (state, action) => {
            state.tabsId = action.payload
        },
        setBirthDate: (state, action) => {
            state.birthDate = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setLastName: (state, action) => {
            state.lastName = action.payload
        },
        setMiddleName: (state, action) => {
            state.middleName = action.payload
        },
        setCitizenship: (state, action) => {
            state.citizenship = action.payload
        },
        setDocNum: (state, action) => {
            state.docNum = action.payload
        },
        setDocType: (state, action) => {
            state.docType = action.payload
        },
        setGender: (state, action) => {
            state.gender = action.payload
        },
        setPhoneNum: (state, action) => {
            state.phoneNum = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setNumberUpperSeats: (state, action) => {
            state.numberUpperSeats = action.payload
        },
        setNumberUpperSeatsBack: (state, action) => {
            state.numberUpperSeatsBack = action.payload
        },
        setNumberLowerSeats: (state, action) => {
            state.numberLowerSeats = action.payload
        },
        setNumberLowerSeatsback: (state, action) => {
            state.numberLowerSeatsBack = action.payload
        },
        setSelectedJdSvg: (state, action) => {
            state.selectedJdSvg = action.payload
        },
        setSelectedJdSvgBack: (state, action) => {
            state.selectedJdSvgBack = action.payload
        },
        setFreePlaces: (state, action) => {
            state.freePlaces = action.payload
        },
        setFreePlacesBack: (state, action) => {
            state.freePlacesBack = action.payload
        },
        setTrId: (state, action) => {
            state.trId = action.payload
        },
        setAllPassengersInfo: (state, action) => {
          state.allPassengersInfo = {
            ...state.allPassengersInfo,
            ...action.payload 
          };
        },
        setPassengerType: (state, action) => {
          state.passengerType = action.payload
        },
        setJdTicketBackData: (state, action) => {
            state.jdTicketBackData = action.payload
        },
        setChosenPriceFilter: (state, action) => {
            state.chosenPriceFilter = action.payload
        },
        setChosenDateFilter: (state, action) => {
            state.chosenDateFilter = action.payload
        },
    }
})

export const { setJdticketData, setJdgetRecommendationSuc, setJdloadingGetRecommendation, setJdgetRecommendationData, setJdgetRecommendationErr, setSearch_id, setSearch_idBack, addAdults, subtractAdults, addChildren, subtractChildren, setDeparture_date_time, setTrain_number, setJdticketInfoData, setSelectedJdItem, setWagonInfo, setSelectedWagon, setSelectedWagonBack, setSelectedSeatsSlice, setSelectedSeatsSliceBack, setTrId, setMaxTabsId, setTabsId, setBirthDate, setCitizenship, setDocNum, setDocType, setEmail, setGender, setLastName, setMiddleName, setName, setNumberLowerSeats, setNumberUpperSeats, setPhoneNum, setSelectedJdSvg, setSelectedJdSvgBack, setFreePlaces, setFreePlacesBack, setAllPassengersInfo, setPassengerType, setJdTicketBackData, setDeparture_date_timeBack, setTrain_numberBack, setSelectedJdItemBack, setWagonInfoBack, setNumberLowerSeatsback, setNumberUpperSeatsBack
    ,setChosenDateFilter, setChosenPriceFilter
 } = slice.actions

export default slice.reducer