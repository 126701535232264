import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom'; // Import useNavigate for React Router navigation
import AddSquere from '@assets/icons/JdIcons/add-square.svg';
import MinusSquere from '@assets/icons/JdIcons/minus-square.svg';
import {addAdults, subtractAdults, addChildren, subtractChildren, setSelectedWagon, setSelectedWagonBack, setSelectedSeatsSlice, setSelectedSeatsSliceBack, setMaxTabsId, setTabsId, setFreePlaces, setSelectedJdSvg, setPassengerType, setSelectedJdSvgBack, setFreePlacesBack} from '@Slice/JdSlice';
import JdTikcetComp from './JdTikcetComp';
import {useRailwayWagonSchemeMutation} from '@services/AllApi';
import {toast} from 'react-toastify';
import moment from 'moment';
import {setNumberLowerSeats, setNumberUpperSeats, setNumberLowerSeatsback, setNumberUpperSeatsBack} from '../../Slice/JdSlice';
import {formatCurrency} from '../../Functions/FormatCurrency'

export default function JdTicketPurchase(props) {
  const dispatch = useDispatch();

  const adults = useSelector(state => state.JdSlice.adults);
  const children = useSelector(state => state.JdSlice.children);
  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const selectedJdItemBack = useSelector(state => state.JdSlice.selectedJdItemBack);
  const wagonInfo = useSelector(state => state.JdSlice.wagonInfo);
  const wagonInfoBack = useSelector(state => state.JdSlice.wagonInfoBack);
  const selectedWagon = useSelector(state => state.JdSlice.selectedWagon);
  const selectedWagonBack = useSelector(state => state.JdSlice.selectedWagonBack);
  const selectedSeat = useSelector(state => state.JdSlice.selectedSeats);
  const selectedSeatsBack = useSelector(state => state.JdSlice.selectedSeatsBack);
  const freePlaces = useSelector(state => state.JdSlice.freePlaces);
  const freePlacesBack = useSelector(state => state.JdSlice.freePlacesBack);

  const tabsId = useSelector(state => state.JdSlice.tabsId);
  const currency = useSelector((state) => state.AllSlice.currency);
  const {jdBackDate} = props;
  const [selectedSeats, setSelectedSeats] = useState("");
  const [railwayWagonScheme, {data: railwayWagonSchemeData, isSuccess: railwayWagonSchemeSuc, isLoading: railwaySchemeLoading}] = useRailwayWagonSchemeMutation();

  const totalPassengers = adults + children;

  const railwayWagonSchemeFnc = wagon => {
    if (jdBackDate && tabsId === 3) {
      dispatch(setSelectedWagonBack(wagon));
      dispatch(setFreePlacesBack(wagon.free_places.split(', ')));
    } else {
      dispatch(setSelectedWagon(wagon));
      dispatch(setFreePlaces(wagon.free_places.split(', ')));
    }

    if ((jdBackDate && tabsId === 2 && wagonInfo) || (!jdBackDate && tabsId === 1 && wagonInfo) || (jdBackDate && tabsId === 3 && wagonInfoBack)) {
      if(wagon.car_scheme_id) {
        const railwaySchemeData = {scheme_id: wagon.car_scheme_id};
        railwayWagonScheme(railwaySchemeData);
        setSelectedSeats([]);
        dispatch(setNumberLowerSeats(0))
        dispatch(setNumberUpperSeats(0))
        dispatch(setNumberLowerSeatsback(0))
        dispatch(setNumberUpperSeatsBack(0))
        if (jdBackDate && tabsId === 3) {
          dispatch(setSelectedSeatsSliceBack([]));
        } else {
          dispatch(setSelectedSeatsSlice([]));
        }
        dispatch(setMaxTabsId(tabsId));
      }else {
        toast.error('scheme_id not found')
      }
    }
  };

  useEffect(() => {
    if(selectedSeats?.length !== totalPassengers && (jdBackDate && tabsId === 2 || !jdBackDate && tabsId === 1)) {
      setSelectedSeats([]);
      dispatch(setSelectedSeatsSliceBack([]));
      dispatch(setSelectedSeatsSlice([]));
      dispatch(setMaxTabsId(tabsId));
    }
  }, [totalPassengers])

  useEffect(() => {
    if (jdBackDate && tabsId === 3) {
      selectedSeats && dispatch(setSelectedSeatsSliceBack([...selectedSeats]));
    } else {
      selectedSeats && dispatch(setSelectedSeatsSlice([...selectedSeats]));
    }
  }, [selectedSeats]);

  useEffect(() => {
    if (railwaySchemeLoading) {
      setSelectedSeats([]);
    } // Reset selected seats if the scheme is loading
  }, [railwaySchemeLoading]);

  useEffect(() => {
    if (selectedSeats?.length > totalPassengers) {
      setSelectedSeats(selectedSeats.slice(0, totalPassengers));
    }
  }, [totalPassengers, selectedSeats]);

  useEffect(() => {
    window.handleSeatClick = seatNumber => {
      if ((jdBackDate && tabsId === 3 ? freePlacesBack : freePlaces).includes(seatNumber)) {
        setSelectedSeats(prevSeats => {
          if (prevSeats.includes(seatNumber)) {
            return prevSeats.filter(seat => seat !== seatNumber);
          } else if (prevSeats.length < totalPassengers) {
            return [...prevSeats, seatNumber];
          } else if (prevSeats.length === totalPassengers) {
            const updatedSeats = [...prevSeats.slice(1), seatNumber];
            return updatedSeats;
          } else {
            toast.error(`You can only select up to ${totalPassengers} seats.`);
            return prevSeats;
          }
        });
      }
    };
  }, [freePlaces, freePlacesBack, totalPassengers]);

  useEffect(() => {
    if (jdBackDate && tabsId === 3) {
      railwayWagonSchemeData && dispatch(setSelectedJdSvgBack(railwayWagonSchemeData));
    } else {
      railwayWagonSchemeData && dispatch(setSelectedJdSvg(railwayWagonSchemeData));
    }
  }, [railwayWagonSchemeData]);

  let svgContent = railwayWagonSchemeData?.data[0];
  svgContent = svgContent?.replace(/�/g, '').replace(/`/g, "'");

  svgContent = svgContent?.replace(/id='Seat(\d+)'/g, (match, seatNumber) => {
    const isFree = freePlaces.includes(seatNumber);
    const isSelected = selectedSeats.includes(seatNumber);
    const fillColor = isSelected ? '#27AE60' : isFree ? '#A7CDEA' : '#CCCCCC';
    const pointerEvents = isFree ? 'auto' : 'none';

    return `
            id='Seat${seatNumber}' 
            onClick="window.handleSeatClick('${seatNumber}')" 
            style="fill: ${fillColor}; cursor: ${isFree ? 'pointer' : 'not-allowed'}; pointer-events: ${pointerEvents};"`;
  });

  svgContent = svgContent?.replace(/<text(.*?)>/g, (match) => {
    return match.replace('<text', '<text style="pointer-events: none;"');
  });

  let svgContentBack = railwayWagonSchemeData?.data[0];
  svgContentBack = svgContentBack?.replace(/�/g, '').replace(/`/g, "'");

  svgContentBack = svgContentBack?.replace(/id='Seat(\d+)'/g, (match, seatNumber) => {
    const isFree = freePlacesBack.includes(seatNumber);
    const isSelected = selectedSeatsBack?.includes(seatNumber);
    const fillColor = isSelected ? '#27AE60' : isFree ? '#A7CDEA' : '#CCCCCC';
    const pointerEvents = isFree ? 'auto' : 'none';

    return `
            id='Seat${seatNumber}' 
            onClick="window.handleSeatClick('${seatNumber}')" 
            style="fill: ${fillColor}; cursor: ${isFree ? 'pointer' : 'not-allowed'}; pointer-events: ${pointerEvents};"`;
  });

  svgContentBack = svgContentBack?.replace(/<text(.*?)>/g, (match) => {
    return match.replace('<text', '<text style="pointer-events: none;"');
  });

  const handleConfirmAndSwitchTab = () => {
    if (selectedSeats?.length == adults + children) {
      const start = window.scrollY;
      const startTime = performance.now();

      const scrollStep = currentTime => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / 300, 1);
        window.scrollTo(0, start * (1 - progress));
        if (progress < 1) {
          requestAnimationFrame(scrollStep);
        }
      };
      requestAnimationFrame(scrollStep);

      if (jdBackDate && tabsId == 3) {
        dispatch(setTabsId(4));
        dispatch(setMaxTabsId(4));
      } else if (jdBackDate && tabsId == 2) {
        dispatch(setTabsId(3));
        dispatch(setMaxTabsId(3));
      } else {
        dispatch(setTabsId(2));
        dispatch(setMaxTabsId(2));
      }
    } else {
      toast.error('Please select at least one seat before proceeding.');
    }
  };

  useEffect(() => {
    if (adults) {
      const newItems = [];

      for (let i = 0; i < adults; i++) {
        newItems.push({
          chdNum: i + 1,
          age: 'adt'
        });
      }
      if (children) {
        for (let i = 0; i < children; i++) {
          newItems.push({
            chdNum: i + 1,
            age: 'chd'
          });
        }
      }
      dispatch(setPassengerType(newItems));
    }
  }, [adults, children]);

  return (
    <div>
      <div className='grid grid-cols-12 gap-6'>
        <div className='col-span-12 lg:col-span-9 h-auto self-start'>
          <div class='shadow-[0px_0px_15px_0px_#00000033] rounded-xl'>
            <div className='hidden lg:flex p-6'>
              <h2 className='text-2xl font-bold'>
                {selectedJdItem?.passenger_departure_station_name} — {selectedJdItem?.passenger_arrival_station_name}
              </h2>{' '}
              &nbsp; {jdBackDate && <h2 className='text-2xl'>туда и обратно, &nbsp;</h2>}
              {jdBackDate ? (
                <>
                  <h2 className='text-2xl'>{moment(selectedJdItem?.departure_date_time).format('D-MMMM')} &nbsp;</h2>
                  <h2 className='text-2xl'>{moment(selectedJdItemBack?.departure_date_time).format('D-MMMM')}</h2>
                </>
              ) : (
                <h2 className='text-2xl'>{moment(selectedJdItem?.departure_date_time).format('D-MMMM')}</h2>
              )}
            </div>

            <div className={`${jdBackDate && tabsId === 2 && 'border-4 border-[#DC874D] lg:mt-4'} p-6 rounded-2xl`}>
              <JdTikcetComp selectedJdItem={selectedJdItem} />
            </div>
            {jdBackDate && selectedJdItemBack && (
              <div className={`${jdBackDate && tabsId === 3 && 'border-4 border-[#DC874D]'} p-6 rounded-2xl`}>
                <JdTikcetComp selectedJdItem={selectedJdItemBack} />
              </div>
            )}
          </div>
          <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
            <h2 className='text-lg lg:text-2xl font-bold'>Укажите количество билетов</h2>
            <div className='grid grid-cols-12 mt-4 gap-5'>
              <div className='col-start-1 col-span-5 flex flex-col lg:flex-row lg:items-center justify-between'>
                <h4 className='text-base lg:text-xl'>Старше от 12 лет</h4>
                <div className='flex items-center'>
                  <img
                    className='cursor-pointer w-8 h-8 lg:w-12 lg:h-12'
                    onClick={() => {
                      if ((!jdBackDate && tabsId == 1) || (jdBackDate && tabsId == 2)) {
                        if (adults > 1 && adults <= children) {
                          dispatch(subtractAdults());
                          dispatch(subtractChildren());
                        } else if (adults > 1) {
                          dispatch(subtractAdults());
                        }
                      } else {
                        toast.error(`Avval ortga qaytib, borish uchun o'zgaritishingiz kerak`);
                      }
                    }}
                    src={MinusSquere}
                    alt=''
                  />
                  <h1 className='mx-4'>{adults}</h1>
                  <img
                    className='cursor-pointer w-8 h-8 lg:w-12 lg:h-12'
                    onClick={() => {
                      if ((!jdBackDate && tabsId == 1) || (jdBackDate && tabsId == 2)) {
                        if (adults < 4) {
                          dispatch(addAdults());
                        }
                      } else {
                        toast.error(`Avval ortga qaytib, borish uchun o'zgaritishingiz kerak`);
                      }
                    }}
                    src={AddSquere}
                    alt=''
                  />
                </div>
              </div>
              <div className='col-start-8 col-span-5 flex flex-col lg:flex-row lg:items-center justify-between'>
                <h4 className='text-base lg:text-xl'>Дети до 12 лет</h4>
                <div className='flex items-center'>
                  <img
                    className='cursor-pointer w-8 h-8 lg:w-12 lg:h-12'
                    onClick={() => {
                      if ((!jdBackDate && tabsId == 1) || (jdBackDate && tabsId == 2)) {
                        children > 0 && dispatch(subtractChildren());
                      } else toast.error(`Avval ortga qaytib, borish uchun o'zgaritishingiz kerak`);
                    }}
                    src={MinusSquere}
                    alt=''
                  />
                  <h1 className='mx-4'>{children}</h1>
                  <img
                    className='cursor-pointer w-8 h-8 lg:w-12 lg:h-12'
                    onClick={() => {
                      if ((!jdBackDate && tabsId == 1) || (jdBackDate && tabsId == 2)) {
                        adults > children && dispatch(addChildren());
                      } else toast.error(`Avval ortga qaytib, borish uchun o'zgaritishingiz kerak`);
                    }}
                    src={AddSquere}
                    alt=''
                  />
                </div>
              </div>
            </div>
            <p className='mt-4 text-xs lg:text-sm'>Укажите возраст на момент отправления!</p>
          </div>

          <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
            <h2 className='text-lg lg:text-2xl font-bold'>Выберите тип вагона и место</h2>
            <div className='flex my-4 lg:my-6 gap-3 overflow-x-auto whitespace-nowrap'>
              {(wagonInfo || wagonInfoBack) &&
                (jdBackDate && tabsId === 3 ? wagonInfoBack : wagonInfo).items?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      railwayWagonSchemeFnc(item);
                    }}
                    className={`p-3 border-2 rounded-lg inline-block ${selectedWagon === item ? 'border-2 border-[#0057BE]' : ''}`}
                  >
                    <p className='text-sm'>
                      {item?.car_type_name} - {item?.car_number}
                    </p>
                    <h1 className='font-bold text-lg'>
                      от {formatCurrency(item.min_ticket_price[currency], currency)}
                    </h1>
                  </div>
                ))}
            </div>
            <div className='w-full'>
              {railwaySchemeLoading && <div className='text-center my-4'>Загрузка схемы вагона...</div>}
              {railwayWagonSchemeSuc &&
                svgContent &&
                (jdBackDate && tabsId === 3 ? (
                  <>
                    <h2 className='font-bold mb-4'>
                      Вагон {selectedWagonBack?.car_type_name}-{selectedWagonBack?.car_number}
                    </h2>
                    <div className='overflow-x-scroll lg:overflow-auto'>
                      <div className='w-[1000px]  lg:w-full' dangerouslySetInnerHTML={{__html: svgContentBack}} />
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className='font-bold mb-4'>
                      Вагон {selectedWagon?.car_type_name}-{selectedWagon?.car_number}
                    </h2>
                    <div className='overflow-x-scroll lg:overflow-auto'>
                      <div className='w-[1000px]  lg:w-full' dangerouslySetInnerHTML={{__html: svgContent}} />
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className='col-span-12 lg:col-span-3 h-auto self-start shadow-[0px_0px_15px_0px_#00000033] p-4 rounded-lg'>
          <h3 className='text-lg lg:text-xl font-bold'>Стоимость билетов</h3>
          <p className='text-sm lg:text-base my-2 font-semibold'>
            {selectedJdItem?.passenger_departure_station_name} & {selectedJdItem?.passenger_arrival_station_name}
          </p>
          {selectedWagon && selectedSeat.length > 0 ? (
            <div className='mt-3 mb-6 border-b-4 pb-4'>
              <p>
                Тип: {selectedWagon?.car_type_name} - {selectedWagon?.car_number}
              </p>
              <p>
                Цена: от {formatCurrency(selectedWagon?.min_ticket_price[currency], currency)}
              </p>
            </div>
          ) : (
            <p className='mb-6'>Будет известна после выбора мест.</p>
          )}
          {tabsId == 3 && (
            <>
              <p className='text-sm lg:text-base my-2 font-semibold'>
                {selectedJdItemBack?.passenger_departure_station_name} & {selectedJdItemBack?.passenger_arrival_station_name}
              </p>
              {selectedWagon && selectedWagonBack && selectedSeatsBack?.length > 0 ? (
                <div className='mt-3 mb-6'>
                  <p>
                    Тип: {selectedWagonBack?.car_type_name} - {selectedWagonBack?.car_number}
                  </p>
                  <p>
                    Цена: от {formatCurrency(selectedWagonBack?.min_ticket_price[currency], currency)}
                  </p>
                </div>
              ) : (
                <p className='mb-6'>Будет известна после выбора мест.</p>
              )}
            </>
          )}
          <button onClick={handleConfirmAndSwitchTab} className='bg-custom-gradient text-[black] font-medium rounded-xl mr-3 py-4 w-full text-lg'>
            Выбрать место
          </button>
        </div>
      </div>
    </div>
  );
}
