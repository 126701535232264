import React, {useEffect, useState} from 'react';
import JdTikcetComp from '../JdTIcketPurchase/JdTikcetComp';
import {useSelector} from 'react-redux';
import clickLogo from '@assets/images/orderImages/click.png';
import humoLogo from '@assets/images/orderImages/humo.png';
import mtcLogo from '@assets/images/orderImages/mtc.png';
import paymeLogo from '@assets/images/orderImages/payme.png';
import paynetLogo from '@assets/images/orderImages/paynet.png';
import payzeLogo from '@assets/images/orderImages/payze.png';
import tkbLogo from '@assets/images/orderImages/tkb.png';
import uzcardLogo from '@assets/images/orderImages/uzcard.png';
import visaLogo from '@assets/images/orderImages/visa.png';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useBookingConfirmMutation} from '@services/Booking';
import moment from 'moment';
import PriceAndSeatsJdTicket from '../PriceAndSeatsJdTicket/PriceAndSeatsJdTicket';

export default function JdPayment(props) {
  const [bookingConfirm, {data: bookingConfirmData, isSuccess: bookingConfirmSuc, isError: bookingConfirmErr, error: bookingConfirmErrData}] = useBookingConfirmMutation();

  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const trId = useSelector(state => state.JdSlice.trId);
  const lang = useSelector(state => state.AllSlice.lang);
  const selectedJdItemBack = useSelector(state => state.JdSlice.selectedJdItemBack);
  const tabsId = useSelector(state => state.JdSlice.tabsId);
  const {jdBackDate} = props;

  const [paymentType, setPaymentType] = useState('');

  const navigate = useNavigate();

  const navigatePaymentPage = (e) => {
    e.preventDefault()
    if (paymentType) {
      if (paymentType == 'uzcard') {
        navigate(`/payment/uzcard/${trId}`);
      } else if (paymentType === 'humo') {
        navigate(`/payment/humo/${trId}`);
      } else {
        const conBookingg = {
          transaction_type: paymentType,
          tr_id: trId,
          card_number: '',
          expire: ''
        };
        bookingConfirm(conBookingg);
      }
    } else {
      toast.error('Пожалуйста, выберите способ оплаты');
    }
  };

  useEffect(() => {
    if (bookingConfirmSuc) {
      window.location.href = bookingConfirmData.payment_url;
    } else if (bookingConfirmData?.message) {
      toast.error(bookingConfirmData?.message);
    }
  }, [bookingConfirmSuc]);

  useEffect(() => {
    if (bookingConfirmErr && bookingConfirmErrData) {
      toast.error(bookingConfirmErrData?.data[lang]);
    }
  }, [bookingConfirmErr]);

  return (
    <div>
      <form onSubmit={navigatePaymentPage}>
        <div className='grid grid-cols-12 gap-6'>
          <div className='col-span-12 lg:col-span-9 h-auto self-start'>
            <div class='shadow-[0px_0px_15px_0px_#00000033] rounded-xl'>
              <div className='hidden lg:flex p-6'>
                <h2 className='text-2xl font-bold'>
                  {selectedJdItem.passenger_departure_station_name} — {selectedJdItem.passenger_arrival_station_name}
                </h2>{' '}
                &nbsp; {jdBackDate && <h2 className='text-2xl'>туда и обратно, &nbsp;</h2>}
                {jdBackDate ? (
                  <>
                    <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')} &nbsp;</h2>
                    <h2 className='text-2xl'>{moment(selectedJdItemBack?.departure_date_time).format('D-MMMM')}</h2>
                  </>
                ) : (
                  <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')}</h2>
                )}
              </div>

              <div className={`p-6 rounded-2xl`}>
                <JdTikcetComp selectedJdItem={selectedJdItem} />
              </div>
              {jdBackDate && (
                <div className='px-6'>
                  <hr className='mt-2 h-[2px] bg-[#DCDDE0] border-none' />
                </div>
              )}
              {jdBackDate && selectedJdItemBack && (
                <div className={`${jdBackDate && tabsId === 3 && 'border-4 border-[#DC874D]'} p-6 rounded-2xl`}>
                  <JdTikcetComp selectedJdItem={selectedJdItemBack} />
                </div>
              )}
            </div>

            <div className='md:border md:rounded-lg py-[10px] md:p-[32px] lg:shadow-[0_2px_4px_rgba(0,0,0,.35)] mt-6'>
              <h2 className='font-bold text-[22px]'>Выберите тип оплаты</h2>

              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-4 gap-4 overflow-hidden'>
                <div className={`w-full border-[3px] rounded-lg flex justify-center items-center h-[150px] px-2 cursor-pointer hover:border-[#3379CB] duration-150 ${paymentType === 'uzcard' && 'border-[#27AE60]'}`} onClick={() => setPaymentType('uzcard')}>
                  <img loading='lazy' src={uzcardLogo} alt='' className='max-w-full' />
                </div>
                <div className={`w-full border-[3px] rounded-lg flex justify-center items-center h-[150px] px-2 cursor-pointer hover:border-[#3379CB] duration-150 ${paymentType === 'humo' && 'border-[#27AE60]'}`} onClick={() => setPaymentType('humo')}>
                  <img loading='lazy' src={humoLogo} alt='' className='max-w-full' />
                </div>
                <div className={`w-full border-[3px] rounded-lg flex justify-center items-center h-[150px] px-2 cursor-pointer hover:border-[#3379CB] duration-150 ${paymentType === 'PAYME' && 'border-[#27AE60]'}`} onClick={() => setPaymentType('PAYME')}>
                  <img loading='lazy' src={paymeLogo} alt='' className='max-w-full' />
                </div>
                <div className={`w-full border-[3px] rounded-lg flex justify-center items-center h-[150px] px-2 cursor-pointer hover:border-[#3379CB] duration-150 ${paymentType === 'CLICK' && 'border-[#27AE60]'}`} onClick={() => setPaymentType('CLICK')}>
                  <img loading='lazy' src={clickLogo} alt='' className='max-w-full' />
                </div>
              </div>

              <h2 className='font-bold text-[20px] mt-8'>Скоро добавим:</h2>
              <div className='grid grid-cols-5 gap-4 my-4'>
                <div className='w-full border-[3px] rounded-lg flex justify-center items-center p-3 grayscale'>
                  <img loading='lazy' src={visaLogo} alt='' className='!max-w-full lg:!w-[70%]' />
                </div>
                <div className='w-full border-[3px] rounded-lg flex justify-center items-center p-3 grayscale'>
                  <img loading='lazy' src={mtcLogo} alt='' className='!max-w-full lg:!w-[70%]' />
                </div>
                <div className='w-full border-[3px] rounded-lg flex justify-center items-center p-3 grayscale'>
                  <img loading='lazy' src={paynetLogo} alt='' className='!max-w-full lg:!w-[70%]' />
                </div>
                <div className='w-full border-[3px] rounded-lg flex justify-center items-center p-3 grayscale'>
                  <img loading='lazy' src={payzeLogo} alt='' className='!max-w-full lg:!w-[70%]' />
                </div>
                <div className='w-full border-[3px] rounded-lg flex justify-center items-center p-3 grayscale'>
                  <img loading='lazy' src={tkbLogo} alt='' className='!max-w-full lg:!w-[70%]' />
                </div>
              </div>

              <h3 className='text-[#27AE60] !text-[16px] font-bold mt-8'>Данные паспорта и банковской карты под защитой!</h3>
            </div>
          </div>
          <PriceAndSeatsJdTicket />
        </div>
      </form>
    </div>
  );
}
