import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {setDeparture_date_time, setTrain_number, setJdTicketBackData, setSelectedJdItem, setWagonInfo, setDeparture_date_timeBack, setTrain_numberBack, setSelectedJdItemBack, setSearch_idBack, setWagonInfoBack} from '@Slice/JdSlice';
import {useRailwayWagonInfoMutation, useRailwayTrainListBackMutation, useRailwayWagonInfoBackMutation} from '@services/AllApi';
import CircularProgress from '@mui/material/CircularProgress';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import mySearchNot from '@assets/images/mySearchNot.png';
import {setMaxTabsId, setTabsId} from '@Slice/JdSlice';
import {formatCurrency} from '../../Functions/FormatCurrency';

const TicketDesign = ({inx, item, railwayWagonInfoLoading, windowWidth, handleChange, railwayWagonInfoBackLoading, activeBtn, editItem}) => {
  const currency = useSelector(state => state.AllSlice.currency);

  const dispatch = useDispatch();
  const editTicket = () => {
    dispatch(setMaxTabsId(0));

    dispatch(setTabsId(0));
  };

  return (
    <div className={` ${editItem ? 'py-8 px-4' : 'p-4'} shadow-[0px_0px_15px_0px_#00000033] w-full rounded-xl mb-4`} key={inx}>
      <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between'>
        <div className='flex flex-col lg:flex-row lg:items-center justify-between gap-4 w-full'>
          <div className='lg:hidden mb-3 flex-col  flex  w-full text-sm gap-2'>
            <div className='flex items-center gap-2 w-max'>
              <p className='bg-[#3379CB] text-[#fff] p-1 rounded-md'>{item.train_name.length > 10 ? item.train_name.slice(0, 10) + '..' : item.train_name}</p>
              <p className='font-semibold'>
                {item.train_number} ({item.train_category})
              </p>
            </div>
            <p className='text-[#8E8E92]'>
              {item.departure_station_name} & {item.arrival_station_name}
            </p>
          </div>
          <div className='flex items-center  justify-center gap-2 w-full'>
            <h2 className='flex lg:items-center flex-col text-base lg:text-2xl w-full  font-bold'>
              {moment.parseZone(item.departure_date_time).format('HH:mm')}
              <p className='text-sm md:w-max  font-normal text-[#8E8E92]'>{item.passenger_departure_station_name}</p>
            </h2>
            <div className='bg-ticketLineBGSm bg-center bg-no-repeat w-full flex items-center justify-center'>
              <p className='text-[#A7A7A7] bg-[#fff] py-1 px-3'>
                {moment.duration(item.trip_duration, 'minutes').hours()} : {moment.duration(item.trip_duration, 'minutes').minutes().toString().padStart(2, '0')}
              </p>
            </div>
            <h2 className='flex lg:items-center flex-col text-base lg:text-2xl w-full font-bold'>
              {moment.parseZone(item.arrival_date_time).format('h:mm')}
              <p className='text-sm   md:w-max font-normal text-[#8E8E92]'>{item.passenger_arrival_station_name}</p>
            </h2>
          </div>

          {/* Train information */}
          <div className=' flex-col hidden  lg:flex items-center text-sm w-1/3'>
            <div className='flex items-center gap-2 w-max'>
              <p className='bg-[#3379CB] text-[#fff] p-1 rounded-md'>{item.train_name.length > 10 ? item.train_name.slice(0, 10) + '..' : item.train_name}</p>
              <p className='font-semibold'>
                {item.train_number} ({windowWidth < 1324 ? item.train_category.slice(0, 8) + '..' : item.train_category})
              </p>
            </div>
            <p className='text-[#8E8E92] '>
              {item.departure_station_name} & {item.arrival_station_name}
            </p>
          </div>

          {/* Seat group information */}
          <div className='lg:text-center  flex items-center gap-1 mt-3 lg:mt-0'>
            {item.car_groups.map((group, idx) => (
              <h2 className='text-base text-[#A7A7A7]' key={idx}>
                {group.car_type_name}
              </h2>
            ))}
          </div>
        </div>
        <div className='lg:pl-8 lg:ml-7 lg:border-l  flex flex-row justify-between lg:flex-col items-center border-[#DCDDE0]'>
          {editItem ? (
            <button className='text-[#0057BE] w-max' onClick={editTicket}>
              Изменить поезд туда
            </button>
          ) : (
            <>
              <h2 className='text-lg lg:text-2xl  text-[#3379CB] font-bold w-max'>
                от {formatCurrency(Math.min(...item.car_groups.map(group => Math.ceil(group.min_ticket_price[currency]))), currency)}
                {/* <span className='text-sm lg:text-xl ml-1'>{currency}</span> */}
              </h2>

              <button className='whitespace-nowrap bg-[#27AE60] rounded-lg flex items-center justify-center p-3 min-w-[120px] w-[160px] text-[#fff] font-medium' onClick={e => handleChange(e, item.departure_date_time, item.train_number, inx, item)}>
                {(railwayWagonInfoLoading || railwayWagonInfoBackLoading) && activeBtn === inx ? <CircularProgress size='24px' color='inherit' sx={{marginLeft: '5px'}} /> : 'Выбор поезд туда'}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function TiketsJd(props) {
  const {t} = useTranslation();
  const jdticketData = useSelector(state => state.JdSlice.jdticketData);
  const jdTicketBackData = useSelector(state => state.JdSlice.jdTicketBackData);
  const jdloadingGetRecommendation = useSelector(state => state.JdSlice.jdloadingGetRecommendation);
  const search_id = useSelector(state => state.JdSlice.search_id);
  const search_idBack = useSelector(state => state.JdSlice.search_idBack);
  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const tabsId = useSelector(state => state.JdSlice.tabsId);
  const value = useSelector(state => state.JdSlice.tabsId);
  const chosenPriceFilter = useSelector(state => state.JdSlice.chosenPriceFilter);
  const chosenDateFilter = useSelector(state => state.JdSlice.chosenDateFilter);
  const {date, fromRailwaysName, toRailwaysName, toRailways, fromRailways, jdBackDate} = props;
  const [activeBtn, setActiveBtn] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [appropriation, setAppropriation] = useState([]);
  const dispatch = useDispatch();

  const [railwayWagonInfo, {data: railwayWagonInfoData, isSuccess: railwayWagonInfoSuc, isLoading: railwayWagonInfoLoading, isError: railwayWagonInfoErr}] = useRailwayWagonInfoMutation();
  const [railwayWagonInfoBack, {data: railwayWagonInfoBackData, isSuccess: railwayWagonInfoBackSuc, isLoading: railwayWagonInfoBackLoading, isError: railwayWagonInfoBackErr}] = useRailwayWagonInfoBackMutation();
  const [railwayTrainListBack, {data: railwayTrainListBackData, isSuccess: railwayTrainListBackSuc, isLoading: railwayTrainListBackLoading, isError: railwayTrainListBackErr}] = useRailwayTrainListBackMutation();

  useEffect(() => {
    if (toRailwaysName && fromRailwaysName && jdBackDate && !jdTicketBackData?.data) {
      const railwayBody = {
        from_: toRailways,
        to: fromRailways,
        time_from: 0,
        time_to: 24,
        date: jdBackDate,
        adult: 1,
        child: 0,
        infant: 0,
        lang: 'ru',
        ...(chosenDateFilter === 'depFirstTime' && {dep_time: 1}),
        ...(chosenDateFilter === 'depLastTime' && {dep_time: -1}),
        ...(chosenDateFilter === 'arrFirstTime' && {arr_time: 1}),
        ...(chosenDateFilter === 'arrLastTime' && {arr_time: -1}),
        ...(chosenPriceFilter === 'cheepFirst' && {price_order: 1}),
        ...(chosenPriceFilter === 'cheepLast' && {price_order: -1})
      };
      railwayTrainListBack(railwayBody);
    }
  }, []);

  useEffect(() => {
    if (railwayTrainListBackData?.data && railwayTrainListBackSuc) {
      dispatch(setJdTicketBackData(railwayTrainListBackData));
      dispatch(setSearch_idBack(railwayTrainListBackData?.data?.search_id));
    }
  }, [railwayTrainListBackData]);

  const handleChange = (event, departure_date_time, train_number, inx, item) => {
    if (jdBackDate) {
      dispatch(setDeparture_date_timeBack(departure_date_time));
      dispatch(setTrain_numberBack(train_number));

      dispatch(setSelectedJdItemBack(item));
      setActiveBtn(inx);

      const railwayBody = {
        search_id: search_idBack ? search_idBack : railwayTrainListBackData.data.search_id,
        train_number: train_number,
        departure_date_time: departure_date_time,
        lang: 'ru'
      };
      railwayWagonInfoBack(railwayBody);
    } else {
      dispatch(setDeparture_date_time(departure_date_time));
      dispatch(setTrain_number(train_number));

      dispatch(setSelectedJdItem(item));
      setActiveBtn(inx);

      const railwayBody = {
        search_id: search_id,
        train_number: train_number,
        departure_date_time: departure_date_time,
        lang: 'ru'
      };
      railwayWagonInfo(railwayBody);
    }
  };

  useEffect(() => {
    if (jdBackDate && tabsId == 1) {
      if (railwayWagonInfoBackSuc && railwayWagonInfoBackData.success === true) {
        dispatch(setWagonInfoBack(railwayWagonInfoBackData?.data));
        value < 2 && dispatch(setMaxTabsId(2));

        dispatch(setTabsId(2));

        window.scrollTo({top: 0, behavior: 'smooth'});
      } else if (railwayWagonInfoBackErr || railwayWagonInfoBackData?.success === false) railwayWagonInfoBackData?.data?.message ? toast.error(railwayWagonInfoBackData.data.message) : toast.error('error');
    } else {
      if (railwayWagonInfoSuc && railwayWagonInfoData.success === true) {
        dispatch(setWagonInfo(railwayWagonInfoData?.data));
        value < 1 && dispatch(setMaxTabsId(1));

        dispatch(setTabsId(1));

        window.scrollTo({top: 0, behavior: 'smooth'});
      } else if (railwayWagonInfoErr || railwayWagonInfoData?.success === false) railwayWagonInfoData?.data?.message ? toast.error(railwayWagonInfoData.data.message) : toast.error('error');
    }
  }, [railwayWagonInfoData, railwayWagonInfoBackData]);

  useEffect(() => {
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setAppropriation(jdBackDate ? jdTicketBackData?.data?.items : jdticketData?.data?.items);
  }, [jdTicketBackData, jdticketData]);

  return (
    <div>
      {/* Main loader for entire component while fetching recommendations */}
      {(jdloadingGetRecommendation || railwayTrainListBackLoading) && (
        <div className='flex justify-center items-center h-[300px]'>
          <CircularProgress size='50px' color='primary' />
        </div>
      )}

      {/* Displaying train details only when not loading */}
      {jdBackDate
        ? fromRailwaysName &&
          toRailwaysName &&
          !railwayTrainListBackLoading && (
            <>
              <h1 className='font-bold text-lg lg:text-3xl lg:mt-8 mb-4'>
                Выбрать поездa из {fromRailwaysName} в {toRailwaysName} на {moment(date, 'YYYY-MM-DD').format('D-MMMM')}
              </h1>
              <TicketDesign inx={0} item={selectedJdItem} railwayWagonInfoLoading={false} windowWidth={windowWidth} handleChange={handleChange} railwayWagonInfoBackLoading={false} activeBtn={activeBtn} editItem={true} />
              <h1 className='font-bold text-lg lg:text-3xl lg:mt-12 mb-4'>
                Выбрать поездa из {toRailwaysName} в {fromRailwaysName} на {moment(jdBackDate, 'YYYY-MM-DD').format('D-MMMM')}{' '}
              </h1>
            </>
          )
        : fromRailwaysName &&
          toRailwaysName &&
          date &&
          !jdloadingGetRecommendation && (
            <h1 className='font-bold text-lg lg:text-3xl lg:mt-8 mb-4'>
              Выбрать поездa из {fromRailwaysName} в {toRailwaysName} на {moment(date, 'YYYY-MM-DD').format('D-MMMM')}
            </h1>
          )}

      {(jdticketData?.data?.items?.length > 0 || jdTicketBackData?.data?.items?.length > 0) && !jdloadingGetRecommendation
        ? (jdBackDate ? jdTicketBackData.data?.items : jdticketData.data?.items)?.map((item, inx) => (
            <TicketDesign inx={inx} item={item} railwayWagonInfoLoading={railwayWagonInfoLoading} windowWidth={windowWidth} handleChange={handleChange} railwayWagonInfoBackLoading={railwayWagonInfoBackLoading} activeBtn={activeBtn} />
          ))
        : !jdloadingGetRecommendation && (
            <div className='flex items-center justify-around flex-col lg:flex-row'>
              <img loading='lazy' src={mySearchNot} alt='' />
              <div className='flex items-center justify-center flex-col  '>
                <h1 className='text-[28px] font-bold'>{t('Here is empty now!')}</h1>
              </div>
            </div>
          )}
    </div>
  );
}

export default TiketsJd;
