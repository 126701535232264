import React from 'react';
import moment from 'moment';

function JdTikcetComp({selectedJdItem}) {
  return (
    selectedJdItem && (
      <div className='flex items-center justify-between lg:py-4'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-24 w-full'>
          <div className='lg:hidden mb-3 flex-col lg:flex-row flex lg:items-center text-sm gap-3'>
            <div className='flex items-center gap-3 w-max'>
              <p className='bg-[#3379CB] text-[#fff] p-1 rounded-md'>{selectedJdItem.train_name.length > 10 ? selectedJdItem.train_name.slice(0, 10) + '..' : selectedJdItem.train_name}</p>
              <p className='font-semibold'>
                {selectedJdItem.train_number} ({selectedJdItem.train_category})
              </p>
            </div>
            <p className='text-[#8E8E92] '>
              {selectedJdItem.departure_station_name} & {selectedJdItem.arrival_station_name}
            </p>
          </div>
          <div className='flex items-center justify-center gap-2 w-full'>
            <h2 className='flex lg:items-center flex-col text-base lg:text-2xl font-bold'>
              {moment.parseZone(selectedJdItem.departure_date_time).format('HH:mm')}
              <p className='text-sm lg:w-max font-normal text-[#8E8E92]'>{selectedJdItem.passenger_departure_station_name}</p>
            </h2>
            <div className='bg-ticketLineBGSm  bg-center bg-no-repeat w-full flex items-center justify-center '>
              <p className='text-[#A7A7A7] bg-[#fff] py-1 px-3 '>
                {' '}
                {moment.duration(selectedJdItem.trip_duration, 'minutes').hours()} : {moment.duration(selectedJdItem.trip_duration, 'minutes').minutes().toString().padStart(2, '0')}
              </p>
            </div>
            <h2 className='flex lg:items-center flex-col text-base lg:text-2xl font-bold'>
              {moment.parseZone(selectedJdItem.arrival_date_time).format('h:mm')}
              <p className='text-sm lg:w-max font-normal text-[#8E8E92]'>{selectedJdItem.passenger_arrival_station_name}</p>
            </h2>
          </div>
          <div className=' hidden lg:flex flex-col items-center  text-sm w-1/3'>
            <div className='flex items-center gap-2 w-max'>
              <p className='bg-[#3379CB] text-[#fff] p-1 rounded-md'>{selectedJdItem?.train_name.length > 10 ? selectedJdItem?.train_name.slice(0, 10) + '..' : selectedJdItem?.train_name}</p>
              <p className='font-semibold'>
                {selectedJdItem?.train_number} ({selectedJdItem?.train_category})
              </p>
            </div>
            <p className='text-[#8E8E92]'>
              {selectedJdItem?.departure_station_name} - {selectedJdItem?.arrival_station_name}
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default JdTikcetComp;
