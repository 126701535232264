import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import JdTikcetComp from '../JdTIcketPurchase/JdTikcetComp';
import {FaChevronDown} from 'react-icons/fa';
import DatePicker from 'react-multi-date-picker';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import {setMaxTabsId, setTabsId} from '@Slice/JdSlice';
import { setEmail, setNumberLowerSeats, setNumberUpperSeats, setNumberUpperSeatsBack, setNumberLowerSeatsback, setPhoneNum, setAllPassengersInfo} from '@Slice/JdSlice';
import PriceAndSeatsJdTicket from '../PriceAndSeatsJdTicket/PriceAndSeatsJdTicket';

function JdPassengerInfo(props) {
  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const selectedWagon = useSelector(state => state.JdSlice.selectedWagon);
  const selectedWagonBack = useSelector(state => state.JdSlice.selectedWagonBack);
  const selectedSeats = useSelector(state => state.JdSlice.selectedSeats);
  const selectedSeatsBack = useSelector(state => state.JdSlice.selectedSeatsBack);

  const docNum = useSelector(state => state.JdSlice.docNum);
  const phoneNum = useSelector(state => state.JdSlice.phoneNum);
  const email = useSelector(state => state.JdSlice.email);
  const numberUpperSeats = useSelector(state => state.JdSlice.numberUpperSeats);
  const numberLowerSeats = useSelector(state => state.JdSlice.numberLowerSeats);
  const numberUpperSeatsBack = useSelector(state => state.JdSlice.numberUpperSeatsBack);
  const numberLowerSeatsBack = useSelector(state => state.JdSlice.numberLowerSeatsBack);
  const allPassengersInfo = useSelector(state => state.JdSlice.allPassengersInfo);
  const passengerType = useSelector(state => state.JdSlice.passengerType);
  const selectedJdItemBack = useSelector(state => state.JdSlice.selectedJdItemBack);
  const tabsId = useSelector(state => state.JdSlice.tabsId);

  const {jdBackDate} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    selectedWagon &&
      selectedWagon.places.forEach(element => {
        if (selectedSeats.includes(element.place_number)) {
          if (element.type === 'Upper') {
            dispatch(setNumberUpperSeats(numberUpperSeats + 1));
          } else if (element.type === 'Lower') {
            dispatch(setNumberLowerSeats(numberLowerSeats + 1));
          }
        }
      });
  }, [selectedWagon]);

  useEffect(() => {
    selectedWagonBack &&
      selectedWagonBack.places.forEach(element => {
        if (selectedSeatsBack.includes(element.place_number)) {
          if (element.type === 'Upper') {
            dispatch(setNumberUpperSeatsBack(numberUpperSeatsBack + 1));
          } else if (element.type === 'Lower') {
            dispatch(setNumberLowerSeatsback(numberLowerSeatsBack + 1));
          }
        }
      });
  }, [selectedWagonBack]);

  const handleChangeVal = (name, value) => {
    dispatch(setAllPassengersInfo({[name]: value}));
  };

  const datePickerCheck = (e, inx) => {
    const day = e && e.day && e.day > 9 ? String(e.day) : e && '0' + e.day;
    const mounth = e && e.month && e.month > 9 ? e.month : e && '0' + e.month;
    const year = e && e.year && String(e.year);

    handleChangeVal(`birthdate${inx}`, day + '.' + mounth + '.' + year);
  };

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(setTabsId(tabsId + 1));
    dispatch(setMaxTabsId(tabsId + 1));

    const start = window.scrollY;
    const startTime = performance.now();

    const scrollStep = currentTime => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / 500, 1);
      window.scrollTo(0, start * (1 - progress));
      if (progress < 1) {
        requestAnimationFrame(scrollStep);
      }
    };
    requestAnimationFrame(scrollStep);
  };

  const handlePasInputChange = (e, inx) => {
    const inputValue = e.target.value.toUpperCase();
    const cleanedValue = inputValue.replace(/[^A-Z0-9]/g, '').substring(0, 9);

    let letters = cleanedValue.slice(0, 2).replace(/[^A-Z]/g, '');
    let numbers = cleanedValue.slice(2).replace(/[^0-9]/g, '');

    // Adjust the behavior for deletion
    if (inputValue?.length < docNum?.length) {
      // Handling backspace/delete
      if (cleanedValue?.length <= 2) {
        letters = cleanedValue.replace(/[^A-Z]/g, '');
        numbers = '';
      } else {
        numbers = cleanedValue.slice(2).replace(/[^0-9]/g, '');
      }
    }

    const formattedValue = `${letters} ${numbers}`;
    handleChangeVal(`passportNum${inx}`, formattedValue.trim());
  };

  useEffect(() => {
    selectedSeats.map((item, inx) => {
      if (!allPassengersInfo[`Citizen${inx}`]) {
        handleChangeVal(`Citizen${inx}`, 'ru');
      }
      if (!allPassengersInfo[`gender${inx}`]) {
        handleChangeVal(`gender${inx}`, 'M');
      }
    });
  }, []);

  return (
    <div>
      <form action='' className='grid grid-cols-12 gap-6' onSubmit={e => handleSubmit(e)}>
        <div className='col-span-12 lg:col-span-9 h-auto self-start'>
          <div class='shadow-[0px_0px_15px_0px_#00000033] rounded-xl'>
            <div className='hidden lg:flex p-6'>
              <h2 className='text-2xl font-bold'>
                {selectedJdItem.passenger_departure_station_name} — {selectedJdItem.passenger_arrival_station_name}
              </h2>{' '}
              &nbsp; {jdBackDate && <h2 className='text-2xl'>туда и обратно, &nbsp;</h2>}
              {jdBackDate ? (
                <>
                  <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')} &nbsp;</h2>
                  <h2 className='text-2xl'>{moment(selectedJdItemBack?.departure_date_time).format('D-MMMM')}</h2>
                </>
              ) : (
                <h2 className='text-2xl'>{moment(selectedJdItem.departure_date_time).format('D-MMMM')}</h2>
              )}
            </div>

            <div className={`p-6 rounded-2xl`}>
              <JdTikcetComp selectedJdItem={selectedJdItem} />
            </div>
            {jdBackDate && (
              <div className='px-6'>
                <hr className='mt-2 h-[2px] bg-[#DCDDE0] border-none' />
              </div>
            )}
            {jdBackDate && selectedJdItemBack && (
              <div className={`${jdBackDate && tabsId === 3 && 'border-4 border-[#DC874D]'} p-6 rounded-2xl`}>
                <JdTikcetComp selectedJdItem={selectedJdItemBack} />
              </div>
            )}
          </div>
          {passengerType.map((item, inx) => (
            <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
              <h1 className='text-2xl font-bold'>
                Введите данные пассажира {selectedSeats.length > 1 && inx + 1} ({item.age == 'adt' ? '12 лет и старше' : 'до 12 лет'})
              </h1>
              <div className='grid grid-cols-1 lg:grid-cols-3 mt-6 gap-8'>
                <label className='flex flex-col' htmlFor=''>
                  Имя
                  <input type='text' value={allPassengersInfo[`name${inx}`]} name={`name`} required className='p-3 border border-[#CCCCCC] rounded-lg mt-1' placeholder='Asad' onChange={e => handleChangeVal(`name${inx}`, e.target.value)} />
                </label>
                <label className='flex flex-col' required htmlFor=''>
                  Фамилия
                  <input value={allPassengersInfo[`lastName${inx}`]} type='text' name={`lastName`} required className='p-3 border border-[#CCCCCC] rounded-lg mt-1' placeholder='Asadov' onChange={e => handleChangeVal(`lastName${inx}`, e.target.value)} />
                </label>
                <label className='flex flex-col' required htmlFor=''>
                  Отчество
                  <input value={allPassengersInfo[`middleName${inx}`]} type='text' required name={`middleName`} className='p-3 border border-[#CCCCCC] rounded-lg mt-1' placeholder='Asadovich' onChange={e => handleChangeVal(`middleName${inx}`, e.target.value)} />
                </label>

                <label className='flex flex-col relative' htmlFor={`citizenship${inx}`}>
                  Гражданство
                  <div className='relative p-3 border border-[#CCCCCC] rounded-lg mt-1 cursor-pointer flex items-center'>
                    <select
                      id={`citizenship${inx}`}
                      required
                      value={allPassengersInfo[`Citizen${inx}`]}
                      onChange={e => handleChangeVal(`Citizen${inx}`, e.target.value)}
                      className='border-none outline-none cursor-pointer appearance-none w-full pl-3 pr-8' // Padding to make space for icon
                    >
                      <option value='uz'>Узбекистан</option>
                      <option value='ru'>Россия</option>
                      <option value='kz'>Казахстан</option>
                    </select>
                    <FaChevronDown className='absolute right-3 text-gray-400 pointer-events-none ' />
                  </div>
                </label>

                <label className='flex flex-col whitespace-nowrap' htmlFor=''>
                  Данные пасспорта / ID карты
                  <input type='text' required className='p-3 border border-[#CCCCCC] rounded-lg mt-1' placeholder='AD 0000000' onChange={e => handlePasInputChange(e, inx)} value={allPassengersInfo[`passportNum${inx}`]} />
                </label>
                <label className='flex flex-col' htmlFor=''>
                  Дата рождения
                  <DatePicker
                    required
                    value={allPassengersInfo[`birthdate${inx}`]}
                    placeholder='DD.MM.YYYY'
                    className='border border-[#CCCCCC] rounded-lg mt-1 w-full'
                    onChange={e => datePickerCheck(e, inx)}
                    inputClass='border-[1px] rounded-lg p-[12px] h-[48px] w-full mt-[8px]'
                    style={{width: '100%', height: '48px'}}
                    format='DD.MM.YYYY'
                  />
                </label>

                <label className='flex flex-col relative' htmlFor={`gender${inx}`}>
                  Пол
                  <div className='relative p-3 border  border-[#CCCCCC] rounded-lg mt-1 cursor-pointer flex items-center'>
                    <select required id={`gender${inx}`} value={allPassengersInfo[`gender${inx}`]} onChange={e => handleChangeVal(`gender${inx}`, e.target.value)} className='border-none outline-none cursor-pointer appearance-none w-full pr-8'>
                      <option value='M'>Мужской</option>
                      <option value='F'>Женский</option>
                    </select>
                    <FaChevronDown className='absolute right-3 text-gray-400 pointer-events-none' />
                  </div>
                </label>
              </div>
            </div>
          ))}
          <div className='shadow-[0px_0px_15px_0px_#00000033] p-6 rounded-xl mt-6'>
            <h1 className='text-2xl font-bold'>Контактная информация</h1>
            <p className='text-[#898989] my-2'>На почту мы отправим билет, вышлем уведомления об изменениях в рейсе или в случае других экстренных ситуаций</p>

            <div className='grid grid-cols-1 lg:grid-cols-2 mt-6 gap-8'>
              <label className='flex flex-col' htmlFor=''>
                Электронная почта
                <input type='text' value={email} name={`email`} required className='p-3 border border-[#CCCCCC] rounded-lg mt-1' placeholder='Электронная почта' onChange={e => dispatch(setEmail(e.target.value))} />
              </label>
              <label className='flex flex-col' htmlFor=''>
                <p>Телефон</p>
                <PhoneInput
                  country={'uz'}
                  value={phoneNum}
                  onlyCountries={['uz']}
                  placeholder='Номер телефона'
                  autoComplete='on'
                  name='phone'
                  inputStyle={{
                    height: '56px',
                    width: '100%',
                    borderRadius: '10px'
                  }}
                  inputProps={{
                    name: 'phone',
                    required: true
                  }}
                  onChange={e => dispatch(setPhoneNum(e))}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.target.form.requestSubmit();
                    }
                  }}
                />
              </label>
            </div>
          </div>
        </div>
        <PriceAndSeatsJdTicket />
      </form>
    </div>
  );
}

export default JdPassengerInfo;
