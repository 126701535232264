import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {formatCurrency} from '../../Functions/FormatCurrency';

export default function PriceAndSeatsJdTicket(props) {
  const selectedJdItem = useSelector(state => state.JdSlice.selectedJdItem);
  const selectedJdItemBack = useSelector(state => state.JdSlice.selectedJdItemBack);
  const selectedSeats = useSelector(state => state.JdSlice.selectedSeats);
  const selectedSeatsBack = useSelector(state => state.JdSlice.selectedSeatsBack);
  const passengerType = useSelector(state => state.JdSlice.passengerType);
  const selectedWagon = useSelector(state => state.JdSlice.selectedWagon);
  const selectedWagonBack = useSelector(state => state.JdSlice.selectedWagonBack);
  const currency = useSelector(state => state.AllSlice.currency);

  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPriceBack, setTotalPriceBack] = useState(0)
  useEffect(() => {
    if(selectedWagon.places && selectedSeats && currency) {
      setTotalPrice([...selectedWagon.places].filter(item => [...selectedSeats].includes(item.place_number)).reduce((sum, item) => sum + Math.ceil(item.max_price[currency]), 0));
      if(selectedWagonBack?.places && selectedSeatsBack) {
        setTotalPriceBack(selectedWagonBack.places && [...selectedWagon.places?.filter(item => [...selectedSeats].includes(item.place_number)), ...selectedWagonBack.places?.filter(item => [...selectedSeatsBack].includes(item.place_number))].reduce((sum, item) => sum + Math.ceil(item.max_price[currency]), 0));
      }
    }
  }, [selectedWagon, selectedWagonBack, currency]);

  return (
    <div className='col-span-12 lg:col-span-3 h-auto self-start shadow-[0px_0px_15px_0px_#00000033] p-4 rounded-lg'>
      <h3 className='text-2xl font-bold'>Стоимость билетов</h3>
      <p className='my-2 font-semibold'>
        {selectedJdItem.passenger_departure_station_name} - {selectedJdItem.passenger_arrival_station_name}
      </p>

      <div className='mb-6'>
        {selectedSeats.length >= 1 &&
          selectedSeats.map((item, inx) => (
            <div className='mb-3'>
              <h2>
                {passengerType[inx]?.age == 'adt' ? 'Взрослый' : 'Ребенок'}: {passengerType[inx]?.chdNum}
              </h2>
              <div className='flex justify-between mb-3'>
                <h2>Место: {item}</h2>
                <h2>{selectedWagon && selectedWagon.places.map(element => element.place_number == item && formatCurrency(element.max_price[currency], currency))}</h2>
              </div>
              <hr />
            </div>
          ))}
      </div>

      {selectedJdItemBack && (
        <p className='my-2 font-semibold'>
          {selectedJdItemBack?.passenger_departure_station_name} - {selectedJdItemBack?.passenger_arrival_station_name}
        </p>
      )}

      <div className='mb-6'>
        {selectedSeatsBack?.length >= 1 &&
          selectedSeatsBack.map((item, inx) => (
            <div className='mb-3'>
              <h2>
                {passengerType[inx]?.age == 'adt' ? 'Взрослый' : 'Ребенок'}: {passengerType[inx]?.chdNum}
              </h2>
              <div className='flex justify-between mb-3'>
                <h2>Место: {item}</h2>
                <h2>{selectedWagonBack && selectedWagonBack.places.map(element => element.place_number == item && formatCurrency(element.max_price[currency], currency))}</h2>
              </div>
              <hr />
            </div>
          ))}
        <div className='mt-3 flex flex-wrap justify-between gap-2'>
          <h1 className='text-2xl font-bold'>Итого</h1>
          <h1 className='text-2xl font-bold'>
            {selectedWagon && !selectedWagonBack && formatCurrency(totalPrice, currency)} {selectedWagon && selectedWagonBack && formatCurrency(totalPriceBack, currency)}
          </h1>
        </div>
      </div>
      <button type='submit' className='bg-custom-gradient text-[black] font-medium rounded-xl mr-3 py-4 w-full flex items-center justify-center gap-2'>
        <p>Проверить заказ и купить</p>
        {props.loaderCircular && <CircularProgress size='24px' color='inherit' sx={{marginLeft: '5px'}} />}
      </button>
    </div>
  );
}
