import React, { useRef, useState } from 'react';
import airportAriline from '@assets/icons/airportAriline.svg'
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition";
import arrowLeftBlack from "@assets/icons/leftarr.svg";
import moment from 'moment';
import './Main.css';
import { useTranslation } from 'react-i18next';

function StrongFlySm({ fromCodeErr, setAirlinesDataFrom, setMyAirlinesCode, airlinesDataFrom, toCodeErr, setAirlinesDataTo, airlinesDataTo, myAirlinesDateInpErr, dateCodeErr, searchAirportsFnc, formData, searchAirportsToFnc, clearSearchLine, inx, setFormData }) {
  const { t } = useTranslation()
  const datePickerRef = useRef();

  const handlePasInputChange = (e, adtInx) => {
    handleChangeVal(`date${adtInx + 1}`, e.format?.("DD-MM-YYYY"));
    handleChangeVal(`dateFormat${adtInx + 1}`, e.format?.("DD-MMMM"));
  };

  const handleChangeVal = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateInput = (value) => {
    const regex = /^[A-Za-zА-Яа-яЁё\s]*$/;
    return regex.test(value);
  };


  return (
    <div id={`searchLine${inx}`} className="bg-[#F7F7F7] searchLineMobile grid  h-[60px] py-[5px] px-[10px] relative rounded-lg border border-[#CCCCCC] flex items-center justify-between mt-[8px]">
      <div>
        {
          formData[`fromCode${inx + 1}`] && formData[`from${inx + 1}`] && !formData[`fromEdit${inx + 1}`] ?
            <div className="flex items-center justify-center relative" onClick={() => { handleChangeVal(`fromEdit${inx + 1}`, true); handleChangeVal(`fromCode${inx + 1}`, '') }}>
              <div className='flex flex-col items-center justify-center'>
                <p className="text-lg font-semibold">{formData[`fromCode${inx + 1}`]}</p>
                <h1 className="text-[12px] font-normal">{formData[`from${inx + 1}`]}</h1>
              </div>
            </div>
            :
            <input
              className={`${fromCodeErr !== false && fromCodeErr == inx && 'placeholder-red-700'} bg-[transparent] capitalize text-center outline-none w-full`}
              type="text"
              autoFocus
              placeholder={t("from")}
              onChange={(e) => {
                if (validateInput(e.target.value)) {
                  handleChangeVal(`from${inx + 1}`, e.target.value);
                  handleChangeVal(`fromActive${inx + 1}`, true);
                  searchAirportsFnc(e.target.value);
                }
              }}
              value={formData[`from${inx + 1}`]}
            />
        }
        {airlinesDataFrom && formData[`fromActive${inx + 1}`] && (
          <div className="searchModalDataList z-10 flex flex-col p-4">
            {airlinesDataFrom?.map((item, index) => (
              <div className=" border-b border-[#CCCCCC] flex flex-col py-4" key={index}>
                <div
                  className="flex items-center justify-between"
                  onClick={() => { handleChangeVal(`from${inx + 1}`, item.cityName); handleChangeVal(`fromActive${inx + 1}`, false); handleChangeVal(`fromCode${inx + 1}`, item.cityIataCode); handleChangeVal(`fromActive${inx + 1}`, false); setMyAirlinesCode(item.cityIataCode); handleChangeVal(`fromEdit${inx + 1}`, false); setAirlinesDataFrom() }}
                >
                  <p className="text-lg">
                    <span className="font-semibold text-[#222222]">{item.cityName}</span>, {item.countryName}
                  </p>
                  <p className="text-[#AEAEAE] font-semibold text-lg">{item.cityIataCode}</p>
                </div>
                <div className="flex flex-col">
                  {item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                    <div
                      key={airInx}
                      onClick={() => { handleChangeVal(`from${inx + 1}`, airport.airportName); handleChangeVal(`fromActive${inx + 1}`, false); handleChangeVal(`fromCode${inx + 1}`, airport.airportIataCode); handleChangeVal(`fromActive${inx + 1}`, false); setMyAirlinesCode(airport.airportIataCode); handleChangeVal(`fromEdit${inx + 1}`, false); setAirlinesDataFrom() }}
                      className=" px-4 hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between"
                    >
                      <p className="flex items-center gap-[4px]">
                        <img loading="lazy" src={airportAriline} alt="Airport Icon" />
                        {airport.airportName}
                      </p>
                      <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

      </div>
      <div className='flex items-center justify-center'>
        <img loading="lazy" className="rotate-[180deg]" src={arrowLeftBlack} alt="" />
      </div>
      <div className='w-full'>
        {
          formData[`toCode${inx + 1}`] && formData[`to${inx + 1}`] && !formData[`toEdit${inx + 1}`] ?
            <div className="flex items-center justify-center" onClick={() => { handleChangeVal(`toEdit${inx + 1}`, true); handleChangeVal(`toCityIataCode${inx + 1}`, '') }}>
              <div className='pl-2 flex flex-col items-center justify-center'>
                <p className="text-lg font-semibold">{formData[`toCode${inx + 1}`]}</p>
                <h1 className="text-[12px] font-normal">{formData[`to${inx + 1}`]}</h1>
              </div>
            </div>
            :
            <input
              className={`${toCodeErr !== false && toCodeErr == inx && 'placeholder-red-700'} bg-[transparent] capitalize outline-none w-[90px] text-center`}
              type="text"
              placeholder={t("where")}
              onChange={(e) => {
                if (validateInput(e.target.value)) {
                  handleChangeVal(`to${inx + 1}`, e.target.value);
                  handleChangeVal(`toActive${inx + 1}`, true);
                  searchAirportsToFnc(e.target.value);
                }
              }}
              value={formData[`to${inx + 1}`]}
            />
        }
        {airlinesDataTo && formData[`toActive${inx + 1}`] && (
          <div className="searchModalDataList2 z-10">
            {airlinesDataTo?.map((item, index) => (
              <div className=" border-b border-[#CCCCCC] flex flex-col p-4" key={index}>
                <div
                  className="flex items-center justify-between"
                  onClick={() => { handleChangeVal(`to${inx + 1}`, item.cityName); handleChangeVal(`toCode${inx + 1}`, item.cityIataCode); handleChangeVal(`toActive${inx + 1}`, false); handleChangeVal(`toCityIataCode${inx + 1}`, item.cityName); handleChangeVal(`toEdit${inx + 1}`, false); setAirlinesDataTo() }}
                >
                  <p className="text-lg">
                    <span className="font-semibold text-[#222222]">{item.cityName}</span>, {item.countryName}
                  </p>
                  <p className="text-[#AEAEAE] font-semibold text-lg">{item.cityIataCode}</p>
                </div>
                <div className="flex flex-col">
                  {item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (

                    <div
                      key={airInx}
                      onClick={() => { handleChangeVal(`to${inx + 1}`, airport.airportName); handleChangeVal(`toCode${inx + 1}`, airport.airportIataCode); handleChangeVal(`toActive${inx + 1}`, false); handleChangeVal(`toCityIataCode${inx + 1}`, airport.airportName); handleChangeVal(`toEdit${inx + 1}`, false); setAirlinesDataTo() }}
                      className="px-[16px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between"
                    >
                      <p className="flex items-center gap-[4px]">
                        <img loading="lazy" src={airportAriline} alt="Airport Icon" />
                        {airport.airportName}
                      </p>
                      <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {inx !== 0 && (
        <div className="absolute top-[-10px] right-[-10px] cursor-pointer" onClick={() => clearSearchLine(inx + 1)}>
          <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="16" r="10" fill="white" />
            <path d="M16.0001 2.6665C8.65341 2.6665 2.66675 8.65317 2.66675 15.9998C2.66675 23.3465 8.65341 29.3332 16.0001 29.3332C23.3467 29.3332 29.3334 23.3465 29.3334 15.9998C29.3334 8.65317 23.3467 2.6665 16.0001 2.6665ZM20.4801 19.0665C20.8667 19.4532 20.8667 20.0932 20.4801 20.4798C20.2801 20.6798 20.0267 20.7732 19.7734 20.7732C19.5201 20.7732 19.2667 20.6798 19.0667 20.4798L16.0001 17.4132L12.9334 20.4798C12.7334 20.6798 12.4801 20.7732 12.2267 20.7732C11.9734 20.7732 11.7201 20.6798 11.5201 20.4798C11.1334 20.0932 11.1334 19.4532 11.5201 19.0665L14.5867 15.9998L11.5201 12.9332C11.1334 12.5465 11.1334 11.9065 11.5201 11.5198C11.9067 11.1332 12.5467 11.1332 12.9334 11.5198L16.0001 14.5865L19.0667 11.5198C19.4534 11.1332 20.0934 11.1332 20.4801 11.5198C20.8667 11.9065 20.8667 12.5465 20.4801 12.9332L17.4134 15.9998L20.4801 19.0665Z" fill="#EF2323" />
          </svg>
        </div>
      )}
      <div>
        <label htmlFor="" className="border-l border-[#CCCCCC] px-2 flex flex-col items-center justify-center">
          <DatePicker
            onChange={(e) => handlePasInputChange(e, inx)}
            minDate={new Date()}
            animations={[transition()]}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            numberOfMonths={2}
            ref={datePickerRef}
            style={{ display: 'none' }}
            value={formData[`dateI${inx + 1}`]}
          />
          <input type="text" placeholder={t("when")} className={`${dateCodeErr !== false && dateCodeErr == inx && 'placeholder-red-700'} h-full w-full bg-[transparent] outline-none text-center`} value={formData[`dateFormat${inx + 1}`] ? formData[`dateFormat${inx + 1}`] : ''} onClick={() => datePickerRef.current.openCalendar()} />
        </label>
      </div>
    </div>
  );
}

export default StrongFlySm;